import './App.scss';

const buttonLinks = [
  {
    label: 'Podcast',
    url: 'https://boyswithbedframes.com/'
  },
  {
    label: 'Music',
    url: 'https://strangeloops.bandcamp.com/'
  },
  {
    label: 'Reading List',
    url: 'https://docs.google.com/spreadsheets/d/1AjAevSUO-7Foh0GWmA4jCT0QQ6P2-JWHRatrNMr8_AE/'
  }
];

const footerText = 'Say hello: ';
const mailTo = 'mailto:hervella.santiago@gmail.com';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <span className='name'>Santiago Hervella</span>
        <span className='subtitle'>Programmer, Musician</span>
        <div className='links'>
          {buttonLinks.map(l => (
              <a href={l.url} target='_blank' rel='noopener noreferrer'>
                <span>{l.label}</span>
              </a>
            )
          )}
        </div>
        <span className='footer'>
          {footerText}
          <a href={mailTo} target='_blank' rel='noopener noreferrer'>
            hervella.santiago@gmail.com
          </a>
        </span>
      </header>
    </div>
  );
}

export default App;
